<template>
  <div class="DarkWhite">
    <v-card
      class="mb-5"
    >
        <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-autocomplete
            v-model="FundCode"
            item-text="Description"
            item-value="FundCode"
            :items="funds"
            hide-details
            color="header"
            label="Filter by Funds"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-autocomplete
            v-model="ClassificationID"
            item-text="Code"
            item-value="ClassificationID"
            :items="classifications"
            hide-details
            color="header"
            label="Filter by Classification"
          >
          </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-menu
              dense
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              :return-value.sync="DateRange"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-combobox
                  dense
                  v-model="DateRange"
                  label="Date Range"
                  color="header"
                  v-bind="attrs"
                  v-on="on"
                  class="mt-5"
                  hide-details
                  clearable
                >
                </v-combobox>
              </template>
              <v-date-picker
                v-model="DateRange"
                no-title
                scrollable
                range
                color="header"
                :max="new Date().toISOString().substr(0, 10)"
              >
                <v-spacer></v-spacer>
                <v-btn text color="header" @click="menu1 = false">
                  Cancel
                </v-btn>
                <v-btn
                  class="white--text"
                  color="header"
                  @click="$refs.menu1.save(DateRange)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-autocomplete
            v-model="UserID"
            item-text="name"
            item-value="UserID"
            :items="users"
            hide-details
            color="header"
            label="Filter by User"
          >
          </v-autocomplete>
          </v-col>
          <!-- <v-col cols="12">
            <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search Item here..."
            color="green darken-2"
            class="mr-2 mt-2"
            dense
            hide-details
          ></v-text-field>
          </v-col> -->
        </v-row>
        </v-card-text>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="itemList"
      :options.sync="options"
      :server-items-length="totalCount"
      :loading="loading"
      :footer-props="footerProps"
      class="elevation-1 pt-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            style="max-width: 300px"
            v-model="searchText"
            append-icon="mdi-magnify"
            placeholder="Search Stock here"
            color="green darken-2"
            class="mr-2 mt-2"
            dense
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-autocomplete
            v-model="Status"
            item-text="description"
            item-value="id"
            :items="return_types"
            hide-details
            color="header"
            style="max-width: 200px"
            class="mr-3"
            label="Filter by Status"
          >
          </v-autocomplete>
          <vue-json-to-csv 
          :json-data="itemListForCSV"
          :labels="{ 
            StockPropertyNo: { title: 'Stock/Property No' },
            RSCODE: { title: 'RS Code' },
            ItemName: { title: 'ItemName' },
            ItemDescription: { title: 'ItemDescription' },
            Qty: { title: 'Qty' },
            DateReturned: { title: 'Date Returned' },
            Status: { title: 'Status' },
            }"
          csv-title="Returned Items">
            <v-btn
              outlined
              color="green darken-2"
              class="mr-2"
              dark
              :loading="loadingCSV"
            >
              <v-icon class="mr-1">mdi-microsoft-excel</v-icon>
              CSV
            </v-btn>
          </vue-json-to-csv>
            <!-- <v-btn
              color="green darken-2"
              class="white--text"
              :disabled="FundCode == 0"
              @click="printIIRUP()"
            >
              <v-icon class="mr-1">mdi-printer</v-icon>
              IIRUP
            </v-btn> -->
        </v-toolbar>
      </template>
      <template v-slot:[`item.Qty`]="{ item }">
        {{ formatQty(item.Qty) }}
      </template>
      <template v-slot:[`item.DateReturned`]="{ item }">
        {{ formatDate(item.DateReturned) }}
      </template>
      <template v-slot:[`item.ReturnTypeID`]="{ item }">
        <span v-if="item.ReturnTypeID == 1" style="color: green">{{ item.Status }}</span>
        <span v-else-if="item.ReturnTypeID == 2" style="color: red">{{ item.Status }}</span>
        <span v-else style="color: grey">{{ item.Status }}</span>
      </template>
      <!-- <template v-slot:[`item.other_Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn
            color="red darken-2"
            class="mr-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="dispose(item)"
            v-if="disposable(item)"
          >
            Add to dispose
          </v-btn>
          <v-btn
            class="mr-2"
            x-small
            disabled
            v-else
          >
          <v-icon small>mdi-check</v-icon>
            added to dispose
          </v-btn>
        </span>
      </template> -->
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn
            color="blue darken-2"
            class="mr-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="view(item)"
          >
            View
          </v-btn>
          <v-btn
            color="green darken-2"
            class="mr-2"
            dark
            outlined
            elevation="2"
            x-small
            @click="print(item)"
          >
            Print
          </v-btn>
        </span>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogPrint" max-width="500px">
          <v-card>
            <v-card-title class="headline pt-5">Generate&nbsp;&nbsp; IIRUP
            </v-card-title>
            <div class="mx-6">
              <v-form @submit.prevent ref="Formref">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                  v-model="FundCode"
                  item-text="Description"
                  item-value="FundCode"
                  :items="funds"
                  hide-details
                  color="header"
                  label="Filter by Funds"
                  :rules="[formRules.required]"
                >
                </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                  v-model="ClassificationID"
                  item-text="Code"
                  item-value="ClassificationID"
                  :items="classifications"
                  hide-details
                  color="header"
                  label="Filter by Classification"
                  :rules="[formRules.numberRequiredZeroAllowed]"
                >
                </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                          v-model="DateType"
                          hide-details
                          color="header"
                          label="Generate items in Date Range"
                        />
                </v-col>
                <v-col cols="12">
                  <v-menu
                    v-if="DateType"
                    dense
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="DateRange"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="DateRange"
                        label="Date Range"
                        color="header"
                        v-bind="attrs"
                        v-on="on"
                        class="mt-5"
                        hide-details
                        clearable
                        :rules="[formRules.required]"
                      >
                      </v-combobox>
                    </template>
                    <v-date-picker
                      v-model="DateRange"
                      no-title
                      scrollable
                      range
                      color="header"
                      :max="new Date().toISOString().substr(0, 10)"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="header" @click="menu2 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        class="white--text"
                        color="header"
                        @click="$refs.menu2.save(DateRange)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>

                  <v-menu
                    v-if="!DateType"
                    dense
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    :return-value.sync="DateEnd"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        dense
                        v-model="DateEnd"
                        label="Date (As of)"
                        color="header"
                        v-bind="attrs"
                        v-on="on"
                        class="mt-5"
                        hide-details
                        clearable
                        :rules="[formRules.required]"
                      >
                      </v-combobox>
                    </template>
                    <v-date-picker
                      v-model="DateEnd"
                      no-title
                      scrollable
                      color="header"
                      :max="new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().substr(0, 10)"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="header" @click="menu3 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        class="white--text"
                        color="header"
                        @click="$refs.menu3.save(DateEnd)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              </v-form>
            </div>
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="dialogPrint = false">Cancel</v-btn>
              <v-btn color="header white--text" @click="confirmPrint()" :disabled="FundCode == 0"><v-icon>mdi-printer</v-icon> Print</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    <RSModal :rsData="viewData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  components: {
    RSModal: () => import("./ViewRS.vue"),
  },
  props: {
    fiscalYear: null
  },
  data: () => ({
    searchText: "",
    searchTimeout: null,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    itemList: [],
    headers: [
      {
          text: 'Stock/Property No.',
          align: 'start',
          sortable: false,
          value: 'StockPropertyNo',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'RS CODE',
          align: 'start',
          sortable: false,
          value: 'RSCODE',
          width: '100',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Item Name',
          align: 'start',
          sortable: false,
          value: 'ItemName',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Item Description',
          align: 'start',
          sortable: false,
          value: 'ItemDescription',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Qty',
          align: 'center',
          sortable: false,
          value: 'Qty',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        // {
        //   text: 'UnitCost',
        //   align: 'start',
        //   sortable: false,
        //   value: 'UnitCost',
        //   class: "green darken-2 white--text text-caption font-weight-bold"
        // },
        {
          text: 'Date Returned',
          align: 'center',
          sortable: false,
          value: 'DateReturned',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Status',
          align: 'center',
          sortable: false,
          value: 'ReturnTypeID',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        // { text: '', value: 'other_Actions', align: 'center', width: '140', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
        { text: 'Actions', value: 'Actions', align: 'center', width: '140', sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      FundCode: 0,
      ClassificationID: 0,
      UserID: 0,
      Status: 0,
      funds: [],
      classifications: [],
      users: [],
      DateRange: null,
      return_types: [],
      dialogPrint: false,
      menu1: false,
      menu2: false,
      menu3: false,
      DateType: false,
      DateEnd: null,
      itemListForCSV: [],
      loadingCSV: true,
      viewData: [],
      itemListDisposal: [],
      showDisposal: false,
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    FundCode: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    ClassificationID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    UserID: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    DateRange: {
      handler(data) {
        if(data != null){
          this.DateType = true;
        }
        this.initialize();
      },
      deep: true,
    },
    Status: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  mounted() {
    this.getAllReturnType();
    this.getFundCodes();
    this.getClassificationByType();
    this.getBudgetTypeAndOffice();
    this.eventHub.$on("closeViewSC", () => {
      this.initialize();
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closeViewSC");
  },
  methods: {
    view(item) {
      this.viewData = item;
    },
    print(item) {
      let link = this.apiUrl + "/rs/report/" + item.RSID;
      window.open(link);
    },
    initialize() {
      this.loading = true;
      this.loadingCSV = true;
      if(this.fiscalYear != null){
      let data = new FormData();
      if (this.options.page) {
        data.append("page", this.options.page);
        data.append("itemsPerPage", this.options.itemsPerPage);
      } else {
        data.append("page", 1);
        data.append("itemsPerPage", 10);
      }
      data.append("tab", this.tab);
      data.append("searchText", this.searchText);
      data.append("FiscalYearID", this.fiscalYear);
      data.append("ClassificationID", this.ClassificationID);
      data.append("FundCode", this.FundCode);
      data.append("UserID", this.UserID);
      data.append("DateRange", this.DateRange && this.DateRange.length > 1 ? this.DateRange : '');
      data.append("Status", this.Status);
      this.axiosCall("/rs/getItemList", "POST", data).then((res) => {
        this.loading = false;
        this.loadingCSV = false;
        this.itemList = res.data.data.result;
        this.itemListForCSV = res.data.data.result
        this.totalCount = res.data.data.total;
        if (this.totalCount <= 10) {
          this.options.page = 1;
        }
      });
      }
    },
    printIIRUP(){
      this.dialogPrint = true;
    },
    confirmPrint(){
      if (this.$refs.Formref.validate()) {
      let dates = this.DateRange || [0,0];
      let DateStart = dates[0];
      let DateEnd = dates[1];
      if(this.DateType == false){
        DateStart = 0;
        DateEnd = this.DateEnd;
      }
      let link = this.apiUrl + "/iirup/report/" + DateStart + "/" + DateEnd + "/" + this.ClassificationID + "/" + this.FundCode;
      window.open(link);
      }
    },
    getAllReturnType() {
      this.axiosCall(
              '/getAllReturnType',
              'GET'
          )
          .then((res) => {
              this.return_types = this.prepend({id: 0, description: 'All'},res.data.data);
          })
		},
    getFundCodes() {
      this.axiosCall(
              '/get/getUniqueFundCodes',
              'GET'
          )
          .then((res) => {
              this.funds = this.prepend({FundCode: 0, Description: 'All'},res.data.data);
          })
		},
    getClassificationByType() {
      this.axiosCall(
              '/get/getClassificationByType/1/1',
              'GET'
          )
          .then((res) => {
              this.classifications = this.prepend({ClassificationID: 0, Code: 'All'},res.data.data);
          })
		},
    getBudgetTypeAndOffice() {
      this.axiosCall(
              '/getOfficeAndOthersForCreateUpdate',
              'GET'
          )
          .then((res) => {
              this.users = this.prepend({UserID: 0, name: 'All'},res.data.data.users);
          })
		},
    prepend(value, array) {
    var newArray = array.slice();
    newArray.unshift(value);
    return newArray;
    },
    disposable(data) {
      let disposed = true;
      this.itemListDisposal.forEach(item => {
        if(item.ICSItemRID == data.ICSItemRID || item.PARItemRID == data.PARItemRID){
          disposed = false
        }
      });
       return disposed;
    },
  },
};
</script>